import {useLocation} from "@reach/router";
import {graphql} from "gatsby";
import React from "react";

import MMLastSection from "../../components/AboveFooterSection";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import MMBanner from "../../components/pages/modemagic/Banner";
import MMHero from "../../components/pages/modemagic/Hero";
import MMReasons from "../../components/pages/modemagic/MMReasons";
import MMMidSection from "../../components/pages/modemagic/MMV3MidSection";
import MMSection2 from "../../components/pages/modemagic/MMV3Section2";
import MMSection3 from "../../components/pages/modemagic/MMV3Section3";
import MMVideoSection from "../../components/pages/modemagic/MMV3VideoSection";
import MMPower from "../../components/pages/modemagic/Power";
import ROILinkSection from "../../components/pages/modemagic/ROILinkSection";
import MMStickyCTA from "../../components/pages/modemagic/StickyCTA";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import "../styles.scss";
import * as styles from "./styles.module.scss";

const Fastlane = ({data: cmsData}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const seoData = cmsData.allSanitySeoMeta.nodes[0];
  const {
    store,
    automation,
    feat,
    reasons,
    midSection,
    lastSection,
    video,
    videoHeadline,
  } = cmsData.allSanityModemagicPage.nodes[0] ?? {};
  const {hero = {}} = cmsData.allSanityFastlanePage.nodes[0] ?? {};

  const location = useLocation();

  const [shouldShowEcomTechBanner, setShouldShowEcomTechBanner] =
    React.useState(false);
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("ref") === "ecomtech") {
      setShouldShowEcomTechBanner(true);
    }
  }, [location]);

  const purpleSectionRef = React.useRef(undefined);

  // to show alternate design when cta is over purple section
  const [alternateCta, setAlternateCta] = React.useState(false);

  // alternate display for cta when scrolled over purple section
  React.useEffect(() => {
    if (purpleSectionRef?.current) {
      const {top, bottom} = purpleSectionRef?.current.getBoundingClientRect();
      const screenHeight = document.documentElement.clientHeight;
      setAlternateCta(top + 34 < screenHeight && bottom + 80 >= screenHeight);
    }
  }, []);
  React.useEffect(() => {
    const updateScrollPercentage = () => {
      if (purpleSectionRef?.current) {
        const {top, bottom} = purpleSectionRef?.current.getBoundingClientRect();
        const screenHeight = document.documentElement.clientHeight;
        setAlternateCta(top + 34 < screenHeight && bottom + 80 >= screenHeight);
      }
    };

    window.addEventListener("scroll", updateScrollPercentage);
    return () => window.removeEventListener("scroll", updateScrollPercentage);
  }, [purpleSectionRef?.current]);

  return (
    <Layout type='modemagic' seoData={seoData} mmv3>
      <StructuredData />
      {shouldShowEcomTechBanner && <MMBanner />}
      <MMHero
        data={hero}
        v3
        theme={{
          heroWrap: styles.heroWrapper,
          heroGrid: styles.heroContainer,
          heading: styles.heading,
          heroContent: styles.content,
          heroCta: styles.cta,
          heroImage: styles.image,
        }}
      />
      {video?.show && (
        <MMVideoSection videoHeadline={videoHeadline} data={video?.video} />
      )}
      <MMSection2 data={feat} />
      <MMMidSection
        data={midSection}
        isMobile={isMobile}
        sectionRef={purpleSectionRef}
      />
      <MMPower isMobile={isMobile} data={automation} v3 />
      <MMReasons data={reasons} isMobile={isMobile} />
      <ROILinkSection />
      <MMSection3 data={store} isMobile={isMobile} />
      <MMLastSection data={lastSection} isMobile={isMobile} />
      {isMobile && <MMStickyCTA data={hero.cta} alternateCta={alternateCta} />}
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "fastlane"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityFastlanePage {
      nodes {
        hero {
          _rawHeading
          _rawDescription
          _rawMobileDescription
          cta {
            text
            responseText
            action
            url
            mobileRedirectUrl
          }
          belowCta
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          mobileImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          mobileAlt
          video {
            type
            showThumbnail
            ytId
            file {
              asset {
                url
              }
            }
            image {
              image {
                asset {
                  url
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              alt
            }
          }
          show
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mmv3"}}) {
      nodes {
        videoHeadline
        video {
          show
          video {
            asset {
              url
            }
          }
        }
        feat {
          _rawHeading
          _rawDescription
          cards {
            _key
            _rawHeading
            subHeading
            description
            image {
              alt
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
            }
            hoveredImage {
              alt
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
            }
          }
        }
        midSection {
          _rawText
        }
        store {
          _rawHeading
          _rawDescription
          show
          ctaUrl
          ctaText
          gif {
            image {
              asset {
                gatsbyImageData
                url
              }
            }
            alt
          }
          items {
            _key
            heading
            description
            icon {
              image {
                asset {
                  url
                  gatsbyImageData
                }
              }
              alt
            }
            image {
              image {
                asset {
                  url
                  gatsbyImageData
                }
              }
              alt
            }
          }
        }
        automation {
          heading
          _rawDescription
          power {
            _rawText
            featurePageReference {
              ctaText
              page {
                ... on SanityFeatureDetailsPage {
                  slug {
                    current
                  }
                }
                ... on SanityHubPage {
                  slug {
                    current
                  }
                }
              }
            }
            cta {
              text
              responseText
              action
              url
            }
            heading
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            mobileImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            mobileAlt
            tabName
            review {
              name
              designation
              review
              photo {
                image {
                  asset {
                    gatsbyImageData
                    url
                  }
                }
                alt
              }
            }
          }
        }
        reasons {
          _rawHeading
          cards {
            _key
            heading
            description
            image {
              alt
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
            }
            hoveredImage {
              alt
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
            }
            ctaText
            ctaUrl
          }
        }
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          ctaUrl
          placeholder
        }
      }
    }
  }
`;

export default Fastlane;
